window.paceOptions = {
    document: false, // disabled
    eventLag: false,
    restartOnPushState: true,
    restartOnRequestAfter: true,
    ajax: {
        trackMethods: [ 'POST','GET'],
        ignoreURLs: ['/import_progress','/brands','/categories','/images','/tags','/websites','/franchisebrand','/franchisegroup']
    }
};

